<template>
    <v-container class="flex items-center justify-center main-container d-flex flex-column align-center my-auto">
        <v-sheet v-if="loading" class="pa-3">
            <v-skeleton-loader class="mx-auto" width="500" type="article, actions"></v-skeleton-loader>
        </v-sheet>
        <v-card class="text-center container" rounded outlined color="primary lighten-5 pa-4" v-else>
            <v-icon color="white">mdi-check</v-icon>
            <h4 class="text-h5 mt-12"> Verification Completed!</h4>
            <div class="text-body-1 mt-6">Your email has been verified successfully 🙌</div>
            <v-btn class="mt-8" elevation='0' color="primary" @click="goToHome()">Done</v-btn>
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: "VerifyEmail",
    data() {
        return {
            loading: true,
        };
    },
    methods: {
        async verify() {
            try {
                const {email, token} = this.$route.params;
                await window.axios.post('/api/verify-email', {email, token});
                this.loading = false;
            } catch (error) {
               window.location.href = '/404';
            }
        },
        goToHome() {
            window.location.href = '/';
        }
    },
    mounted() {
        this.verify();
    }
}
</script>

<style>
.main-container {
    width: 500px;
}

.v-icon {
    position: absolute !important;
    font-size: 55px !important;
    border-radius: 50% !important;
    top: -40px;
    background-color: var(--v-primary-darken1);
    padding: 10px;
    margin-left: -35px;
}
</style>